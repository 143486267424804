export const CheckCondition = (conditions = [], target = 'OrderAmount') => {
  let checkParam
  switch (target) {
    case 'OrderAmount':
    case 'ProductAmount':
    case 'OptionalSelectAchievement':
    case 'OrderRealAmount':
    case 'ProductAmountExcludes':
      checkParam = 'FullAmount'
      break
    case 'OrderQuantity':
    case 'ProductQuantity':
    case 'ProductQuantityExcludes':
      checkParam = 'Quantity'
      break
  }
  const Result = {}
  for (let Index = 0; Index < conditions.length; Index++) {
    if (Index > 0 && parseInt(conditions[Index][checkParam] || '0') <= parseInt(conditions[Index - 1][checkParam]|| '0')) {
      Result[Index] = `Offer/Condition${checkParam}Invalid`
    }
  }
  return Result
}
